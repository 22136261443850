.None {
    display: none !important;
}

.Empty {
    display: flex !important;
}

.AddressFrom, .AddressTo, .PhoneOrder, .Name, .PhoneCall, .RateEmpty {
    display: none;
    width: 100%;
    color: #FF0900;
    font-size: 12px;
    margin-top: 5px;
}

.App {
    width: calc(100vw - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
}

.Background {
    height: 100vh;
    width: 100vw;
    background-image: url('./../images/BG.png');
    background-size: cover;
    background-position: center;
    position: fixed;
    z-index: -1;
}

.OrderContainer {
    width: 500px;
    background-color: rgb(100, 100, 100);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;


    .Logo {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .Star {
            height: 100px;
            animation-name: spin;
            animation-duration: 3s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }

        .Logotype {
            height: 71px;
            width: 150px;
        }

        @keyframes spin {
            0% {
                transform: rotateY(0deg);
            }
            50% {
                transform: rotateY(180deg);
            }
            100% {
                transform: rotateY(360deg);
            }
        }
    }

    .InputContainer {
        width: calc(100% - 30px);
        height: 50px;
        padding: 0 15px;
        margin-top: 20px;
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .InputCircle {
            width: 15px;
            position: relative;

            &::before {
                position: absolute;
                content: '';
                width: 12px;
                height: 12px;
                top: calc(50% - 6px);
                border-radius: 50%;
            }
        }

        .CircBlue::before {
            background-color: #1500FF;
        }

        .CircRed::before {
            background-color: #FF0900;
        }

        .InputFrom, .InputTo {
            width: calc(100% - 30px);
            height: calc(100% - 20px);
            padding: 10px 0;
            border: none;
            outline: none;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            color: #464646;
        }
    }

    .RatesContainer {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .ScrollLeft {
            width: 30px;
            height: 30px;

            img {
                width: 100%;
                transform: rotate(180deg);
            }
        }

        .ScrollRight {
            width: 30px;
            height: 30px;

            img {
                width: 100%;
            }
        }

        .Rates {
            width: calc(100% - 60px);
            display: flex;
            flex-direction: row;
            align-items: stretch;
            overflow-x: scroll;
            scrollbar-width: none;
    
            &::-webkit-scrollbar {
                width: 0;
                display: none;
            }
    
            .Rate {
                min-width: fit-content;
                padding: 10px 15px;
                background-color: rgb(100, 100, 100);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-family: Montserrat;
                color: white;
                transition: color 0.1s linear, background-color 0.1s linear;
    
                .RateImg {
                    height: 20px;
                    width: 57px;
                }
    
                .RateName {
                    font-size: 12px;
                }
    
                .RatePrice {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
    
            .Rate + .Rate {
                margin-left: 5px;
            }
    
            .Chosen {
                background-color: rgb(255, 255, 255);
                color: #464646;
            }
        }
    }

    .RateDescription {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        color: white;

        .RateClass {
            font-weight: 600;
            font-size: 14px;
        }

        .RateCars {
            margin-top: 5px;
            font-size: 12px;
        }

        .RateConditions {
            margin-top: 5px;
            font-size: 12px;
        }
    }

    .InputContainer {
        width: calc(100% - 30px);
        height: 50px;
        padding: 0 15px;
        margin-top: 20px;
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .PreNum {
            display: flex;
            align-items: center;
        }

        .InputNumber {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            padding: 10px 0;
            border: none;
            outline: none;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            color: #464646;
        }
    }

    .InputContainerName {
        width: calc(100% - 30px);
        height: 50px;
        padding: 0 15px;
        margin-top: 20px;
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .InputName {
            width: 100%;
            height: calc(100% - 20px);
            padding: 10px 0;
            border: none;
            outline: none;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            color: #464646;
        }
    }

    .OrderTaxi {
        margin-top: 20px;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: #181818;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: Montserrat;
        color: white;
        font-size: 16px;
        font-weight: 500;
    }

    .SendError {
        margin-top: 20px;
        color: #FF0900;
    }

    .SendSuccess {
        margin-top: 20px;
        color: white;
    }
}

.CarImage {
    position: relative;
    height: 120px;
    width: 540px;

    .CarAbsolute1 {
        position: absolute;
        height: 200px;
        right: -100px;
        top: calc(50% - 100px);
    }

    .CarAbsolute2 {
        position: absolute;
        height: 100px;
        left: -100px;
        top: calc(50% - 20px);
    }
}

.ContactsContainer {
    width: 500px;
    background-color: rgb(100, 100, 100);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .InputContainer {
        width: calc(100% - 30px);
        height: 50px;
        padding: 0 15px;
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .PreNum {
            display: flex;
            align-items: center;
        }

        .InputNumber {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            padding: 10px 0;
            border: none;
            outline: none;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            color: #464646;
        }
    }

    .GetCall {
        width: 100%;
        height: 50px;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #181818;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: Montserrat;
        color: white;
        font-size: 16px;
        font-weight: 500;
    }

    .SendError2 {
        margin-top: 20px;
        color: #FF0900;
    }

    .SendSuccess2 {
        margin-top: 20px;
        color: white;
    }

    .MediaLinks {
        margin-top: 20px;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .WhatsappLink, .TelegramLink {
            height: 100%;
            width: calc(50% - 35px);
            padding: 0 15px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            .MediaImg {
                height: 30px;
                width: 30px;
            }

            .MediaName {
                width: calc(100% - 30px);
                text-align: center;
                color: white;
                font-weight: 500;
                font-size: 16px;
            }
        }

        .WhatsappLink {
            background-color: #3EE95E;
        }

        .TelegramLink {
            background-color: #28A8E9;
        }
    }
}

@media (max-width: 580px) {
    .OrderContainer, .ContactsContainer {
        width: calc(100% - 40px);
    }

    .CarImage {
        width: 100%;
    }
}

@media (max-width: 430px) {
    .Star {
        height: 80px !important;
    }

    .Logotype {
        height: 57px !important;
        width: 120px !important;
    }
}

@media (max-width: 400px) {
    .MediaImg {
        width: 25px !important;
        height: 25px !important;
    }

    .MediaName {
        width: calc(100% - 25px) !important;
        font-size: 14px !important;
    }

    .CarImage {
        height: 100px !important;
    }

    .CarAbsolute1 {
        height: 150px !important;
        top: calc(50% - 75px) !important;
        right: -80px !important;
    }

    .CarAbsolute2 {
        height: 80px !important;
        left: -80px !important;
    }
}

@media (max-width: 350px) {
    .Star {
        height: 70px !important;
    }
}

@media (max-width: 340px) {
    .MediaImg {
        width: 20px !important;
        height: 20px !important;
    }

    .MediaName {
        width: calc(100% - 20px) !important;
        text-align: end !important;
        font-size: 13px !important;
    }
}

@media (max-width: 330px) {
    .Star {
        height: 60px !important;
    }
}