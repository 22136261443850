@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html::-webkit-scrollbar, body::-webkit-scrollbar {
    width: 0;
    display: none;
}

ul {
    display: flex;
    flex-direction: row;
}

li {
    list-style-type: none;
}

button {
    background-color: unset;
    border-radius: unset;
    border: unset;
    cursor: pointer;
}

a {
    text-decoration: unset;
    color: unset;
}

body {
    margin: 0;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    font-family: Montserrat;
    color: #464646;
    background-image: url('./../images/BG.png');
    background-size: cover;
    background-position: center;
}

html {
    width: 100vw;
    overflow-x: hidden;
}

$base-color: #1f6eff;